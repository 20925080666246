import React from 'react';
import './possibility.css';
import ImageSlider from "./ImageSlider";

const Possibility = () => {
  const slides = [

    { url: "http://www.tiktechno.com/image-1.jpg", title: "beach" },
    { url: "http://www.tiktechno.com/image-2.jpg", title: "boat" },
    { url: "http://www.tiktechno.com/image-3.jpg", title: "forest" },
    { url: "http://www.tiktechno.com/image-4.jpg", title: "city" },
    { url: "http://www.tiktechno.com/image-5.jpg", title: "italy" },
    { url: "http://www.tiktechno.com/image-6.jpg", title: "beach" },
    { url: "http://www.tiktechno.com/image-7.jpg", title: "boat" },
    { url: "http://www.tiktechno.com/image-8.jpg", title: "forest" },
    { url: "http://www.tiktechno.com/image-9.jpg", title: "city" },
   
  ];

  const containerStyles = {
    width: "362px",
    height: "280px",
    margin: "0 auto",
  };

  return (
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image" style={containerStyles}>
        <ImageSlider slides={slides} />
      </div>
      <div className="gpt3__possibility-content">
        <h4> </h4>
        <h1 className="gradient__text">Glimpse of Our Events and DJ's </h1>
        <h1 className="gradient__text">The possibilities are <br/> beyond your imagination </h1>
        
        <p>In the heart of the pulsating bass,
        Where rhythm and melody interlace,
        A transcendental journey begins,
        Where souls unite and time suspends.
        The energy surges, electrifying the air,
        Techno's embrace, a euphoric affair.</p>

        <h4> </h4>
      </div>
    </div>
  );
};

export default Possibility;
