// import React, { useState } from 'react';
// import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import logo from '../../assets/logo1.png';

// import './navbar.css';

// const Navbar = () => {
//   const [toggleMenu, setToggleMenu] = useState(false);

//   const handleLinkClick = (sectionId) => {
//     const section = document.querySelector(sectionId);
//     if (section) {
//       section.scrollIntoView({ behavior: 'smooth' });
//     }
//     setToggleMenu(false);
//   };

//   return (
//     <div className="gpt3__navbar">
//       <div className="gpt3__navbar-links">
//         <div className="gpt3__navbar-links_logo">
//           <img src={logo} alt="Logo" />
//         </div>
//         <div className="gpt3__navbar-links_container">
//           <p><a href="#home">Home</a></p>
//           <p><a href="#wgpt3">Raves</a></p>
//           <p><a href="#possibility">Contest</a></p>
//           <p><a href="#features">Merch</a></p>
//           <p><a href="#blog">Blog</a></p>
//           <p><a href="#connect" onClick={() => handleLinkClick("#connect")}>Contact</a></p>
//           <Link to="/Gallery">Gallery</Link>
//         </div>
//       </div>
     


//       <div className="gpt3__navbar-menu">
//         {toggleMenu
//           ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
//           : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
//         {toggleMenu && (
//           <div className="gpt3__navbar-menu_container scale-up-center">
//             <div className="gpt3__navbar-menu_container-links">
//               <p><a href="#home">Home</a></p>
//               <p><a href="#wgpt3">Raves</a></p>
//               <p><a href="#possibility">Contest</a></p>
//               <p><a href="#features">Merch</a></p>
//               <p><a href="#blog">Blog</a></p>
//               <p><a href="#connect" onClick={() => handleLinkClick("#connect")}>Contact</a></p>
//             </div>
            



//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Navbar;

/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx      */ 
// <div className="gpt3__navbar-sign">
// <p>Sign in</p>
// <button type="button">Sign up</button>
// </div>

// <div className="gpt3__navbar-menu_container-links-sign">
//               <p>Sign in</p>
//               <button type="button">Sign up</button>
//             </div>


/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/


import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import logo from '../../assets/logo1.png';

import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleLinkClick = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setToggleMenu(false);
  };

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="#home">Home</a></p>
          <p><a href="#wgpt3">Raves</a></p>
          <p><a href="#possibility">Contest</a></p>
          <p><a href="#features">Merch</a></p>
          <p><a href="#blog">Blog</a></p>
          <p><a href="#connect" onClick={() => handleLinkClick("#connect")}>Contact</a></p>
        
        </div>
      </div>
     


      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p><a href="#home">Home</a></p>
              <p><a href="#wgpt3">Raves</a></p>
              <p><a href="#possibility">Contest</a></p>
              <p><a href="#features">Merch</a></p>
              <p><a href="#blog">Blog</a></p>
              <p><a href="#connect" onClick={() => handleLinkClick("#connect")}>Contact</a></p>
          
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
