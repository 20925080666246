import React from 'react';
import Features2 from '../../components/features2/Features2';
import './featuresq.css';

const features2Data = [
  {
    title: 'Consultation ',
    text: 'We begin by understanding your vision, preferences, and requirements. We will discuss your events theme, budget, and any specific ideas you have in mind.',
  },
  {
    title: 'Planning',
    text: 'Based on the information gathered, our expert event planners will create a detailed plan that includes timelines, logistics, and a comprehensive list of services to be provided.',
  },
  {
    title: 'Design and Decor',
    text: 'Our creative team will collaborate with you to design a decor concept that aligns with your events theme and atmosphere. We will create mock-ups and present you with options to choose from.',
  },
  {
    title: 'Entertainment and Services',
    text: 'We will curate the perfect entertainment package for your event, whether its a DJ, live performances, or specialized services like proposal planning or cultural presentations.',
  },

  {
    title: 'Execution',
    text: ' On the day of the event, our experienced team will handle all the logistics, ensuring seamless coordination between vendors, setup, and execution of the plan. Sit back, relax, and enjoy your event while we take care of everything.',
  },

  {
    title: 'Post-Event',
    text: ' After your event, we will follow up with you to ensure your satisfaction and gather feedback. We value your input as it helps us continuously improve our services.',
  },



];

const Featuresq = () => (
  <div className="gpt3__features1 section__padding" id="features">
    <div className="gpt3__features-heading1">
      <h1 className="gradient__text1">
      How we work?</h1>
      <p> </p>
    </div>
    <div className="gpt3__features-container1">
      {features2Data.map((item, index) => (
        <Features2 title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Featuresq;
