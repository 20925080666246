
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// import './Gallery.css';

import 'react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from 'react-image-gallery';

import { Link } from 'react-router-dom';


const Gallery6 = () => {
  const images = [
    {
      original: process.env.PUBLIC_URL + '/custom (1).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (1).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (2).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (2).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (3).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (3).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (4).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (4).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (5).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (5).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (6).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (6).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (7).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (7).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (8).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (8).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (9).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (9).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (10).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (10).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (11).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (11).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (12).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (12).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (13).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (13).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (14).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (14).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (15).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (15).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/custom (16).webp',
      thumbnail: process.env.PUBLIC_URL + '/custom (16).webp',
    },
  ];

  
  
  return (
    
    <div >
      <Link className='gallery-text' to="/OurServices" >Tap here to go Our Services</Link> {/* This is the path link */}
      <ImageGallery items={images} />
    </div>
    
  );
};

export default Gallery6;
