
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import './content.css';
import Video from '../../assets/Video.mp4';
import Feature from '../../components/feature/Feature';


const Content = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  // SVG click handler for navigating to the gallery page
  const handleSvgClick = () => {
    // Use history.push to navigate to the gallery page
    // Replace '/gallery' with the actual path to your gallery page
    // e.g., history.push('/gallery') or any other routing method you are using
  };

  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <Carousel
        showThumbs={false}
        selectedItem={activeSlide}
        onChange={handleSlideChange}
        showStatus={false}
        showIndicators={false}
        useKeyboardArrows
        emulateTouch
      >
        <div className="gpt3__whatgpt3_A section__margin">
          <video className="background-video" autoPlay muted>
            <source src={Video} type="video/mp4" />
          </video>
        </div>
      </Carousel>
      <div className="gpt3__whatgpt3-feature">
        <Feature
          title="The motto of Tik Techno is:"
          style={{ wordBreak: 'break-word' }}
          text="Connecting Techno Enthusiasts, One Beat at a Time This motto embodies our mission to bring together techno lovers from around the world, creating a vibrant community united by their passion for electronic music. Tic Techno serves as a platform that connects DJs, event organizers, and partygoers, fostering a sense of unity and shared experiences within the techno scene. Through our platform, we aim to facilitate the discovery of new events, artists, and connections, enriching the overall techno experience for our users. Join Tic Techno and let the beats forge connections that transcend boundaries."
        />
      </div>
      



        
  </div>
  );
};

export default Content;
