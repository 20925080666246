import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
      <video className="background-video" autoPlay  muted >
       <source src={blog01} type="video/mp4"  />
      
      
     </video>
          
       
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="Sep 26, 2021" text="Rave to the Rhythm: Dive into the Electrifying Universe of Techno Parties" />
        <Article imgUrl={blog03} date="Sep 26, 2021" text="Techno Fever: The Ultimate Guide to Unforgettable Dancefloor Experiences" />
        <Article imgUrl={blog04} date="Sep 26, 2021" text="Euphoria Unleashed: Immersive Techno Parties That Will Blow Your Mind" />
        <Article imgUrl={blog05} date="Sep 26, 2021" text="Techno Underground: Discovering the Dark and Enigmatic Side of Electronic Music" />
      </div>
    </div>
  </div>
);

export default Blog;

// <Article  date="Sep 26, 2021" text="Unleashing the Beat: Exploring the Thrilling World of Techno Parties" />

// <video className="background-video" autoPlay  muted >
// <source src={blog01} type="video/mp4"  />


// </video>

