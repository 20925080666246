// import React from 'react';
// import ReactDOM from 'react-dom';

// import App from './App';
// import './index.css';

// ReactDOM.render(<App />, document.getElementById('root'));

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Import React Router components
import App from './App';
import Gallery from './components/Gallery'; // Import your Home component
import Gallery2 from './components/Gallery2'; // Import your Home component
import Gallery3 from './components/Gallery3'; // Import your Home component
import Gallery4 from './components/Gallery4'; // Import your Home component
import Gallery5 from './components/Gallery5'; // Import your Home component
import Gallery6 from './components/Gallery6'; 
import Gallery7 from './components/Gallery7'; // Import your Home component
import Gallery8 from './components/Gallery8';
import OurServices from './containers/ourServices/OurServices';
import './index.css';



ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} /> {/* Render App component at the root URL */}
      <Route path="/OurServices" element={<OurServices />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery" element={<Gallery />} /> {/* Define a route for the Home component */}

      <Route path="/Gallery2" element={<Gallery2 />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery3" element={<Gallery3 />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery4" element={<Gallery4 />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery5" element={<Gallery5 />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery6" element={<Gallery6 />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery7" element={<Gallery7 />} /> {/* Define a route for the Home component */}
      <Route path="/Gallery8" element={<Gallery8 />} /> {/* Define a route for the Home component */}
     </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

