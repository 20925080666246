// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// import './Gallery.css';

// import 'react-image-gallery/styles/css/image-gallery.css';
// import { Link } from 'react-router-dom'; 
// import ImageGallery from 'react-image-gallery';


// const Gallery = () => {
//   const images = [
//     {
//       original: process.env.PUBLIC_URL + '/dj1.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj1.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj2.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj2.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj3.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj3.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj4.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj4.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj5.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj5.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj6.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj6.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj7.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj7.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj8.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj8.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj9.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj9.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj10.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj10.webp',
//     },
//     {
//       original: process.env.PUBLIC_URL + '/dj11.webp',
//       thumbnail: process.env.PUBLIC_URL + '/dj11.webp',
//     },
    
   
//     // {
//     //   original: process.env.PUBLIC_URL + '/djmp41',
//     //   thumbnail: process.env.PUBLIC_URL + '/djmp41',
//     // },
//     // {
//     //   original: process.env.PUBLIC_URL + '/djmp42',
//     //   thumbnail: process.env.PUBLIC_URL + '/djmp42',
//     // },
//     // {
//     //   original: process.env.PUBLIC_URL + '/djmp43',
//     //   thumbnail: process.env.PUBLIC_URL + '/djmp43',
//     // },
//     // {
//     //   original: process.env.PUBLIC_URL + '/djmp44',
//     //   thumbnail: process.env.PUBLIC_URL + '/djmp44',
//     // },
//     // {
//     //   original: process.env.PUBLIC_URL + '/djmp45',
//     //   thumbnail: process.env.PUBLIC_URL + '/djmp45',
//     // },
//   ];
  
//   return (
    
//     <div >
//       <Link className='gallery-text' to="/OurServices" >Tap here to go Our Services</Link> {/* This is the path link */}
//       <ImageGallery items={images} />
//     </div>
    
//   );
// };

// export default Gallery;




import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './Gallery.css';

import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';

const Gallery = () => {
  // Your existing images array with low-quality thumbnails
  const images = [
    {
            original: process.env.PUBLIC_URL + '/dj1.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj1.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj2.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj2.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj3.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj3.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj4.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj4.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj5.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj5.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj6.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj6.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj7.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj7.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj8.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj8.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj9.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj9.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj10.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj10.webp',
          },
          {
            original: process.env.PUBLIC_URL + '/dj11.webp',
            thumbnail: process.env.PUBLIC_URL + '/dj11.webp',
          },
          
    // ... Rest of your images ...
  ];

  // State to keep track of whether high-quality images are loaded
  const [highQualityLoaded, setHighQualityLoaded] = useState(false);

  // Event handler to mark high-quality images as loaded
  const handleImageLoad = () => {
    setHighQualityLoaded(true);
  };

  // Helper function to generate the image tag for the gallery
  const renderImage = (item) => {
    return (
      <div className="image-gallery-image">
        {highQualityLoaded ? (
          <img
            src={item.original}
            alt=""
            onLoad={handleImageLoad}
            style={{ opacity: highQualityLoaded ? 1 : 0, transition: 'opacity 0.0s',  width: '50%', height: '50%'  }} // Fade-in effect for high-quality images
          />
        ) : (
          <img
            src={item.thumbnail}
            alt=""
            style={{ transition: 'none' ,  width: '50%', height: '100%' }} // Apply a blur effect for the low-quality thumbnail
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Link className="gallery-text" to="/OurServices">
        Tap here to go Our Services
      </Link>
      {/* This is the path link */}
      <ImageGallery items={images} renderItem={renderImage} showFullscreenButton={false} showPlayButton={false} />
    </div>
  );
};

export default Gallery;















// max-height: calc(74vh - 36px) 

