import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Step into the Realm of Techno: Where the Future Unfolds',
    text: 'Immerse yourself in the pulsating beats, where the future comes alive. Techno transcends time and space, taking you on a journey beyond imagination. Surrender to the rhythm, let the music guide your soul, and witness the power of sound shaping your reality.',
  },
  {
    title: 'Unleashing Endless Possibilities: Embrace the Techno Revolution',
    text: 'In the world of techno, boundaries dissolve, and creativity knows no limits. Its a realm where innovation thrives, pushing the boundaries of whats possible. Embrace the spirit of experimentation, where artists mold sound into an otherworldly experience. Join the movement and be part of shaping the future of music.',
  },
  {
    title: 'Ignite the Trust: Uniting Through Techno',
    text: 'Techno connects people from all walks of life. It bridges gaps, dissolves differences, and creates a harmonious bond on the dancefloor. In this collective energy, trust blossoms, as strangers become friends, and the barriers crumble. Embrace the power of unity, where music becomes the language that unites us all.',
  },
  {
    title: 'Elevate Your Senses: A Sonic Journey Like No Other',
    text: 'Prepare to be transported to a realm where sound becomes tangible, and vibrations awaken your senses. Let the music permeate every fiber of your being, as the bass reverberates through your body. Surrender to the hypnotic melodies, and let the rhythm guide your every move. Its a journey of liberation, where time dissolves and pure bliss awaits.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">
      Embrace techno's electrifying energy.
      Step boldly into the future of sound.
      Leave the past behind, make your mark on the dancefloor.
      The future calls—answer and create today.</h1>
      <p> </p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
