








import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';
import {  Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

const Header = () => (

  
  <div className="gpt3__header section__padding" id="home">

 
    <div className="gpt3__header-content">



    <TrackVisibility>
    {({ isVisible }) => (
      <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
        {isVisible ? (
          <h1 className="gradient__text">Welcome to TikTechno!</h1>
        ) : (
          <span className="tagline"></span>
        )}
      </div>
    )}
  </TrackVisibility>
      <p>Welcome to TikTechno, your premier destination for unforgettable events and celebrations! We are an experienced event company specializing in a wide range of services, including DJ entertainment, event party decor, wedding after parties, bachelor parties, proposal plans, baby showers, cultural events, Indian festivals, fashion shows, and much more. Our dedicated team of professionals is committed to bringing your vision to life and creating extraordinary experiences for you and your guests.</p>


      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Book Tickets</button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div>
    </div>

    <div className="gpt3__header-image">
    
    <Col >
    <p className='gradient__header_text1'>Don’t wait, let us handle your next party!</p>
    <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                <img src={ai} />
                </div>}
            </TrackVisibility>
     </Col>
    </div>
  </div>
);

export default Header;
