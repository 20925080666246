
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// import './Gallery.css';

import 'react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from 'react-image-gallery';

import { Link } from 'react-router-dom';


const Gallery4 = () => {
  const images = [
    {
      original: process.env.PUBLIC_URL + '/cul_1.webp',
      thumbnail: process.env.PUBLIC_URL + '/cul_1.webp',
    },
    {
      original: process.env.PUBLIC_URL + '/cul_2.webp',
      thumbnail: process.env.PUBLIC_URL + '/cul_2.webp',
    },
    {
      original: process.env.PUBLIC_URL + '/cul_3.webp',
      thumbnail: process.env.PUBLIC_URL + '/cul_3.webp',
    },
    {
      original: process.env.PUBLIC_URL + '/cul_4.webp',
      thumbnail: process.env.PUBLIC_URL + '/cul_4.webp',
    },
    {
      original: process.env.PUBLIC_URL + '/cul_5.webp',
      thumbnail: process.env.PUBLIC_URL + '/cul_5.webp',
    },
  ];

  
  
  return (
    
    <div >
      <Link className='gallery-text' to="/OurServices" >Tap here to go Our Services</Link> {/* This is the path link */}
      <ImageGallery items={images} />
    </div>
    
  );
};

export default Gallery4;
