
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// import './Gallery.css';

import 'react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from 'react-image-gallery';

import { Link } from 'react-router-dom';


const Gallery3 = () => {
  const images = [
    {
      original: process.env.PUBLIC_URL + '/baby (1).webp',
      thumbnail: process.env.PUBLIC_URL + '/baby (1).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/baby (2).webp',
      thumbnail: process.env.PUBLIC_URL + '/baby (2).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/baby (3).webp',
      thumbnail: process.env.PUBLIC_URL + '/baby (3).webp',
    },
    {
      original: process.env.PUBLIC_URL + '/baby (4).webp',
      thumbnail: process.env.PUBLIC_URL + '/baby (4).webp',
    },
  ];

  
 
  return (
    
    <div >
      <Link className='gallery-text' to="/OurServices" >Tap here to go Our Services</Link> {/* This is the path link */}
      <ImageGallery items={images} />
    </div>
    
  );
};

export default Gallery3;
