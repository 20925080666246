import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer , Blog, Possibility, Featuresq, Features,Content, OurServices, Header , Contact } from './containers';
import {  Brand, Navbar } from './components';


import './App.css';


const App = () => {
  
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        
        <Header />
      </div>
      
      <Brand />
      <Content />
      <OurServices />
      <Featuresq />
      <Features />
      <Possibility />
      <Blog />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;

// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Footer, Blog, Possibility, Featuresq, Features, Gallery, WhatGPT3, Header, Contact } from './containers';
// import { Brand, Navbar } from './components';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import './App.css';

// const App = () => {
//   return (
//     <div className="App">
//       <div className="gradient__bg">
//         <Navbar />
//         <Header />
//       </div>

//       <Brand />
//       <Router>
//         <Routes>
//            <Route path="/" element={<WhatGPT3 />} />
//           <Route path="/Gallery" element={<Gallery />} />
//         </Routes>
//       </Router>

//       {/* Add the rest of your components */}
     
//       <Featuresq />
//       <Features />
//       <Possibility />
//       <Blog />
//       <Contact />
//       <Footer />
//     </div>
//   );
// };

// export default App;

// <WhatGPT3/>


// App.js

// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Footer, Blog, Possibility, Featuresq, Features, WhatGPT3, Header, Contact, Gallery } from './containers';
// import { Brand, Navbar } from './components';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import './App.css';

// const App = () => {
//   return (
//     <div className="App">
//       <div className="gradient__bg">
//         <Navbar />
//         <Header />
//       </div>

//       <Brand />
//       <Router>
//         <Routes>
//           <Route path="/" element={<WhatGPT3 />} />
//           <Route path="/Gallery" element={<Gallery />} /> {/* Gallery route */}
//         </Routes>
//       </Router>

//       {/* Add the rest of your components */}
     
//       <Featuresq />
//       <Features />
//       <Possibility />
//       <Blog />
//       <Contact />
//       <Footer />
//     </div>
//   );
// };

// export default App;

// App.js

// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Footer, Blog, Possibility, Featuresq, Features, WhatGPT3, Header, Contact } from './containers';
// import { Brand, Navbar, Gallery } from './components';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import './App.css';

// const App = () => {
//   return (
//     <Router> {/* Wrap the entire application with the BrowserRouter */}
//       <div className="App">
//         <div className="gradient__bg">
//           <Navbar />
//           <Header />
//         </div>

//         <Brand />
//         <Routes>
//           <Route path="/" element={<WhatGPT3 />} />
        
//         </Routes>

//         {/* Add the rest of your components */}
//         <Featuresq />
//         <Features />
//         <Possibility />
//         <WhatGPT3 />
//         <Gallery/>
//         <Blog />
//         <Contact />
//         <Footer />
//       </div>
//     </Router>
//   );
// };

// export default App;


// <Route path="/Gallery" element={<Gallery />} /> {/* Gallery route */} 