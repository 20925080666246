
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dj from '../../svg/dj.svg';
import wed from '../../svg/wed.svg';
import baby from '../../svg/baby.svg';
import cul from '../../svg/cul.svg';
// import asian from '../../svg/asian.svg';
import bday from '../../svg/bday.svg';
import artist from '../../svg/artist.svg';
import paint from '../../svg/paint.svg';
import './ourServices.css';
import '../../components/feature/feature.css';

function OurServices() {
 

  const features = [
    {
      title: "DJ Parties",
      text: "At TikTechno, we understand that every event is unique, and we take pride in delivering personalized services tailored to your specific needs and preferences.",
      linkTo: "/gallery",
      svg: <img src={dj} alt="DJ Icon" />
    },
    {
      title: "Wedding Parties",
      text: "For those looking to extend the magic beyond the wedding ceremony, our wedding after parties are the ideal choice. We create lively and unforgettable celebrations.",
      linkTo: "/gallery2",
      svg: <img src={wed} alt="Wedding Icon" />
    },
    {
      title: "Gender Revealation",
      text: "Celebrating the arrival of a little one? Our baby shower services are designed to make the occasion joyous and memorable for both the parents-to-be and their loved ones.",
      linkTo: "/gallery3",
      svg: <img src={baby} alt="Baby Icon" />
    },
    {
      title: "Cultural Events",
      text: "TikTechno is also proud to offer services for cultural events, Indian festivals, and fashion shows. We understand the importance of preserving traditions and creating immersive experiences.",
      linkTo: "/gallery4",
      svg: <img src={cul} alt="Cultural Icon" />
    },
    // {
    //   title: "Bollwood Tech",
    //   text: "TikTechno is a record label and experiential series that showcases the beauty of South Asian culture, music, and rhythm within the global language of house and techno.",
    //   linkTo: "/gallery5",
    //   svg: <img src={asian} alt="Asian Icon" />
    // },
    {
      title: "CustomPals Parties",
      text: "Immerse in unforgettable moments with CustomPals Parties! Discover personalized themes, stunning bar décor, and an extraordinary celebration that's uniquely YOU. Let's make your dream party a reality.",
      linkTo: "/gallery6",
      svg: <img src={bday} alt="Birthday Icon" />
    },

    {
      title: "Emerging Artists",
      text: "Unlock your creative potential with our 'Emerging Artist' services, designed to empower and showcase the talent of rising stars in the world of Tik Techno.",
      linkTo: "/gallery7",
      svg: <img src={artist} alt=" artist " />
    },

    {
      title: "Artistic Vibe Session",
      text: "Unlock your inner creativity with our Artistic Vibes Sessions, where you can immerse yourself in a world of artistic expression through sip n' paint, wall art decoration, and art workshops. ",
      linkTo: "/gallery8",
      svg: <img src={paint} alt=" paint " />
    }
  




  ];


  const renderSlides = () =>
  

  
  features.map((feature, index) => (
    
    <div key={index} className="gpt3__features-container_1">
      <div className="gpt3__features-container__feature-title_2">
        <h1>{feature.title}</h1>
        
      </div>
      
      <Link className="gpt3__features-container__feature-text_2"  to={feature.linkTo}>Tap Image to see Gallery
      <div className="gpt3__features-container__feature-svg">
        {feature.svg}
      </div>
      </Link>
      
      <div className="gpt3__features-container__feature-text">
        <p>{feature.text}</p>
      </div>
      
    </div>
  ));


return (
  
  <div className="gpt3__whatgpt3__ section__margin_2" id="wgpt3">
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Our Services</h1>
    </div>
    <div className="slider-container__">
      {/* Use a wrapping div */}
      <Slider
  dots={false}
  slidesToShow={3}
  slidesToScroll={2}
  centerMode={true}
  autoplay={true}
  autoplaySpeed={1500}
  responsive={[
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]}
>
  {renderSlides()}
</Slider>
    </div>
  </div>
);
}

export default OurServices;
