import React from 'react';
import logo from '../../assets/logo1.png';
import { FaInstagram, FaPhone } from "react-icons/fa";
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">
      Our Goals</h1>
      
      <h1 style={{
        fontSize: '24px',
        lineHeight: '1.5',
        color: 'var(--color-text)',
        fontFamily: 'var(--font-family)',
      }}>
        At TikTechno, we believe that every event should be an extraordinary experience. With our dedicated team and attention to detail, we will transform your vision into reality. Contact us today to discuss your upcoming event and let us create a truly memorable celebration for you and your guests.
      </h1>
    
      </div>

      

    <div className="gpt3__footer-btn">
       <p></p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
      <img src={logo} alt="gpt3_logo" />
      
        <p>611 South DuPont Highway,</p>
        <p>Suite 102, Dover, DE 19901</p> <br /><p> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Social Media</p>

        <a href="https://www.instagram.com/tiktechnonyc" target="_new" style={{ color: '#ffffff', fontSize: '20px', marginRight: '1rem', textDecoration: 'none' }}>
      <FaInstagram size={30} style={{ color: '#ffffff', marginRight: '1rem' }} />
      TikTechno
    </a>
        
       
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>611 South DuPont Highway,</p>
        <p>Suite 102, Dover, DE 19901</p>
        
                            
        <p> +1(929) 496-9494</p>
                        
        
        <p>events@tiktechno.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 TikTechno. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;

// <img src={gpt3Logo} alt="gpt3_logo" />